import React from 'react';
import { graphql } from 'gatsby';
import {
  Box,
  Button,
  Center,
  Divider,
  Flex,
  Heading,
  Text,
} from '@chakra-ui/react';
import { Pizza, Popcorn, TagChevron } from 'phosphor-react';
import { Banner, RecommendationWidget } from 'exoclick-react';

import { BaseHeading, BaseScene, BaseSeo } from '@components/base';
import { TapeList } from '@components/tape';
import { TagHomeList } from '@components/tag';

const flexWrapper = {
  pb: 1,
  justifyContent: 'space-between',
  alignItems: 'flex-end',
};

export default function HomePageTemplate({
  data: { allTapeByDate, site },
  pageContext: { trendingTapes, tagsWithCount },
}) {
  const primaryMenuLinks = React.useMemo(
    () => site.siteMetadata?.primaryMenuLinks || [],
    [site]
  );
  const primaryMenuLinksObj = React.useMemo(
    () =>
      primaryMenuLinks.reduce(
        (total, link) => ({ ...total, [link.label.toLowerCase()]: link }),
        {}
      ),
    [primaryMenuLinks]
  );

  const newestTapes = React.useMemo(
    () =>
      allTapeByDate?.nodes?.map((node, i) => ({
        ...node,
        isLazyLoading: i > 2,
      })) || [],
    [allTapeByDate]
  );

  return (
    <BaseScene pt={{ base: 4, '2xs': '30px' }}>
      <Heading
        as='h2'
        mb={{ base: 2, '2xs': 4 }}
        textAlign='center'
        fontSize={{ base: 'md', '2xs': 'lg' }}
      >
        💚💛❌{' '}
        <Text
          as='span'
          display={{ base: 'block', '3sm': 'inline' }}
          lineHeight={2}
        >
          Watch Pinay Porn Videos For Free
        </Text>{' '}
        ❌💜💙
      </Heading>
      <Box as='section' pb={4}>
        <Box pb={6}>
          <Flex {...flexWrapper}>
            <BaseHeading as='h3' icon={Popcorn}>
              New Releases
            </BaseHeading>
            <Button as='a' size='sm' href={primaryMenuLinksObj['newest'].to}>
              View More
            </Button>
          </Flex>
          <Divider />
        </Box>
        <TapeList tapes={newestTapes} />
        <Center pt={4} flexDir='column'>
          <Center pb={4}>
            <RecommendationWidget zoneId={4856200} />
          </Center>
          <Button
            as='a'
            w='full'
            size='sm'
            href={primaryMenuLinksObj['newest'].to}
          >
            View More New Releases
          </Button>
          <Divider mt={2} />
        </Center>
      </Box>
      <Center flexDir='column'>
        <Banner zoneId={4856160} />
        <Banner zoneId={4864560} />
      </Center>
      <Box as='section' pb={4}>
        <Box pb={6}>
          <Flex {...flexWrapper}>
            <BaseHeading as='h3' icon={Pizza}>
              Trending
            </BaseHeading>
            <Button as='a' size='sm' href={primaryMenuLinksObj['trending'].to}>
              View More
            </Button>
          </Flex>
          <Divider />
        </Box>
        <TapeList tapes={trendingTapes} />
        <Center pt={4} flexDir='column'>
          <Center pb={4}>
            <RecommendationWidget zoneId={4856200} />
          </Center>
          <Button
            as='a'
            w='full'
            size='sm'
            href={primaryMenuLinksObj['trending'].to}
          >
            View More Trending
          </Button>
          <Divider mt={2} />
        </Center>
      </Box>
      <Center flexDir='column'>
        <Banner zoneId={4856160} />
        <Banner zoneId={4864560} />
      </Center>
      <Box as='section'>
        <Box pb={6}>
          <Flex {...flexWrapper}>
            <BaseHeading as='h3' icon={TagChevron}>
              Tags
            </BaseHeading>
            <Button as='a' size='sm' href={primaryMenuLinksObj['tags'].to}>
              View More
            </Button>
          </Flex>
        </Box>
        <TagHomeList tagsWithCount={tagsWithCount} />
        <Center pt={4} flexDir='column'>
          <Button
            as='a'
            w='full'
            size='sm'
            href={primaryMenuLinksObj['tags'].to}
          >
            View Tags
          </Button>
          <Divider mt={2} />
        </Center>
      </Box>
    </BaseScene>
  );
}

export function Head({ data }) {
  const { siteUrl, title, titleDescription, description } =
    data.site?.siteMetadata;
  const siteTitle = React.useMemo(
    () => `${title} ∘ ${titleDescription}`,
    [title, titleDescription]
  );

  const schemaGraphs = React.useMemo(
    () => [
      {
        '@type': ['WebPage', 'CollectionPage'],
        '@id': siteUrl,
        url: siteUrl,
        name: siteTitle,
        isPartOf: {
          '@id': `${siteUrl}/#website`,
        },
        description,
        breadcrumb: {
          '@id': `${siteUrl}/#breadcrumb`,
        },
        inLanguage: 'en-US',
      },
    ],
    [siteUrl, siteTitle, description]
  );

  return (
    <BaseSeo schemaGraphs={schemaGraphs}>
      <title id='site-title'>{siteTitle}</title>
      <meta
        name='exoclick-site-verification'
        content='7c82e1b914dae3cdbaf27e0e74aba540'
      ></meta>
    </BaseSeo>
  );
}

export const query = graphql`
  query homeQuery {
    site {
      siteMetadata {
        title
        titleDescription
        description
        siteUrl
        primaryMenuLinks {
          label
          to
        }
      }
    }
    allTapeByDate: allTape(
      limit: 9
      sort: { fields: created_at, order: DESC }
    ) {
      nodes {
        slug
        title
        duration
        cover_image_url
        tags {
          id
          name
        }
      }
    }
  }
`;
